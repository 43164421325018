<template>
  <div
    id="dropdown-list"
  >
    <n-select
      v-model:value="selectedValue"
      class="dropdown-list"
      filterable
      size="medium"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
    />
  </div>
</template>
<script>
import { ref } from 'vue'


export default {
  name: 'DropdownList',
  props: {
    type: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Please Select an Item',
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    options: {
        type: Array,
        required: false,
        default: null,
    },
    value: {
      type: String,
      default: null
    }
  },
  emits: ['valueChanged'],
  data:() => ({
      selectedValue: ref(null),
  }),
  watch: {
      selectedValue: {
          immediate: true,
          handler: function(newVal, oldVal) {
              if (newVal != null) {
                this.$emit('valueChanged', newVal);
              }
          }
      },
      value: {
        immediate: true,  
        handler: function(newVal, oldVal) {
              this.selectedValue = newVal
        }
      },
      default: {
          immediate: true,
          handler: function(newVal, oldVal) {
              if (newVal != null) {
                  this.selectedValue = newVal;
              }
          }
      },
  },
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
    #dropdown-list {
    svg {
      padding-top: 4px;
    }
  }

  .n-select {
      text-align: left;
  }

</style>