import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/stats';

export default {
  async findElectricityGeneralByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/general`, { params });
  },
  async findElectricityVoltageByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/voltage`, { params });
  },
  async findElectricityFrequencyByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/frequency`, { params });
  },
  async findElectricityPowerfactorByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/powerfactor`, { params });
  },
  async findElectricityCurrentByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/current`, { params });
  },
  async findWaterByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/water/general`, { params });
  },
  async findElectricityByTenantIdDeviceKva(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/tenant/${tenantId}/electricity/kva`, { params });
  },
  async findElectricityByTenantIdDeviceKwh(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/tenant/${tenantId}/electricity/kwh`, { params });
  },
  async findWaterByTenantIdDeviceGeneral(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/tenant/${tenantId}/water/general`, { params });
  },
  async findElectricityByOwningOrgIdTenantKwh(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/organization/${tenantId}/electricity/kwh`, { params });
  },
  async findElectricityByOwningOrgIdTenantKva(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/organization/${tenantId}/electricity/kva`, { params });
  },
  async findWaterByOwningOrgIdTenantGeneral(tenantId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/organization/${tenantId}/water/general`, { params });
  },
};